<style lang="scss" scoped>
.box {
  position: fixed;
  right: 16px;
  top: 144px;
  z-index: 2;

  .box-main {
    width: 0px;
    transition: 0.5s all;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding-bottom: 16px;
    overflow: hidden;

    .main {
      width: 375px;
      padding-left: 24px;
      padding-right: 24px;
      overflow: hidden;
    }
  }
}

.box-help {
  width: 22px;
  height: 44px;
  position: absolute;
  left: -22px;
  top: 12px;
  background-image: url("../../../../assets/features/hidden.svg");
  cursor: pointer;

  &#show {
    background-image: url("../../../../assets/features/show.svg");
  }
}

#showBox {
  width: 375px;
}

.header {
  width: 100%;
  display: flex;
  position: relative;
  height: 60px;
      border-bottom: 2px solid rgba(1, 153, 153, 1);
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    white-space: nowrap;
    align-items: center;
  .header-item {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;
    cursor: pointer;

    div {
      width: 100%;
      text-align: center;
    }

    .line {
      width: 100%;
      height: 2px;
      background: rgb(230,0,18);
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
    }

    &#active {
      .line {
        display: block;
      }

      font-weight: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .header-item:nth-child(1) {
    width: 128px;
  }

  .header-item:nth-child(2) {
    width: 156px;
  }
}

.table {
  // margin-top: 10px;

  .table-item:nth-child(odd) {
    background: none;
  }

  .table-item {
    width: 100%;
    height: 34px;
    display: flex;
    position: relative;
    background: rgba(1, 153, 153, 0.1);

    .spec {
      position: absolute;
      left: 0;
      top: 0;
    }

    .row{
      width: 40%;
    }
    .rows{
      width: 60%;
      padding-right: 12px;
    }
    .row {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      padding-left: 12px;
      font-size: 13px;
      overflow: hidden;
      white-space: wrap;
      // padding-right: 16px;
      word-break: break-all;
    }
    .row-right {
      // width: 51%;
      width: 189px;
    }
    .row-left {
      // width: 49%;
      width: 213px;
    }
    // .row:nth-child(2) {
    //   // word-break: break-all;
    //   // color: rgba(0, 0, 0, 0.8);
    // }

    .rows {
      color: rgb(0, 0, 0, 0.6);
    }
  }
}

.showSize {
  cursor: pointer;
  right: 114px;
  top: 64px;
  width: 32px;
  height: 32px;
  position: fixed;
  z-index: 3;
}

#unShowSize {
  background-image: url("../../../../assets/unShowSize.svg");
}

#showSize {
  background-image: url("../../../../assets/showSize.svg");
}

#left0 {
  right: 0;
}
</style>
<template>
  <div class="feature">
    <div
      class="showSize"
      @click="showSize"
      :id="isShowSize ? 'unShowSize' : 'showSize'"
    ></div>
    <div class="box" :id="showBox ? '' : 'left0'">
      <div
        class="box-help"
        @click="showBox = !showBox"
        :id="showBox ? '' : 'show'"
      ></div>
      <div class="box-main" :id="showBox ? 'showBox' : ''">
        <div class="main">
          <div class="header">
              参数描述
             <!-- <div
              @click="select=2"
              class="header-item"
              :id="select == 2 ? 'active' : ''"
              >
              <div>Basic parameters</div>
              <div class="line"></div>
            </div> -->
        
          </div>

          <div class="table" v-if="select == 1">
            <div class="table-item" v-for="item of tableData1">
              <img
                src="./spec.png"
                v-show="item[2] == true"
                class="spec"
                alt=""
              />
              <div class="row" style="white-space:wrap">
                {{ item[0] }}
              </div>
              <div class="row rows">
                {{ item[1] }}
              </div>
            </div>
          </div>

          <div class="table" v-else>
            <div class="table-item" v-for="item of tableData2">
              <div class="row row-left">{{ item[0] }}</div>
              <div class="row rows row-right">{{ item[1] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../../lib/bus";

export default {
  data() {
    return {
      isShowSize: false,
      select: 1,
      showBox: true,
      isDis:false
    
    };
  },
  computed: {
    tableData1() {
      let data=[
        ['切口缝宽','0.05-0.1mm'],
        [
          '空行速度','60m/min'
        ],[
          '切割厚度','0.17-3mm 视材料厚度而定'
        ],[
          '最大切割速度','≤16m/min'
        ],[
          '管材加工范围 ','圆管 Φ16-Φ120mm'
        ],[
          '机床定位精度','±0.1mm'
        ],[
          'z轴调整范围','65mm'
        ],[
          'W轴调整范围','360°'
        ],
        [
          'Y轴单次送料长度','1200mm'
        ],[
          '机床重复定位精度 ','±0.1mm/1000mm'
        ]
      ]
      return data;
    },
    tableData2(){
      let data=[
          ['Operating Temperature Range (°C)','-25~+60'],
          ['Relative Humidity ','0~100% '],
          ['Max. Operating Altitude (m)','4000'],
          ['Cooling Method','Natural Convection'],
          ['User Interface','LED, LCD (Optional)'],
          ['Communication','WiFi, RS485 or LAN or 4G or DI (Ripple Control or DRM) (Optional)'],
          ['Communication Protocols ','Modbus-RTU (SunSpec Compliant)'],
          ['Dimension (W×H×D mm)','441×507×210'],
          ['Noise Emission (dB)','<30'],
          ['Topology','Non-isolated'],
          ['Self-consumption at Night (W)','<1'],
          ['Ingress Protection Rating','IP66'],
          ['DC Connector','MC4 (2.5 ~ 4m㎡)'],
          ['AC Connector','AC connector']
        
        ]
      return data
    }
  },
  watch: {
    "$store.state.mesh"() {
      this.isShowSize = false;
      bus.$emit("hiddenSize");
    },
  },
  methods: {
    showSize() {
      if(this.isDis==true){
        return
      }else{
        this.isDis=true
        setTimeout(() => {
          this.isDis=false
        }, 1000);
      }
      this.isShowSize = !this.isShowSize;
      if (this.isShowSize) {
        bus.$emit("showSize",this.$store.state.mesh);
        // bus.$emit("displayMesh",this.$store.state.mesh);
      } else {
        bus.$emit("hiddenSize", this.$store.state.mesh);
        // bus.$emit("unDisplayMesh",this.$store.state.mesh);
      }
    },
  },
  created() {},
  destroyed() {
    if (this.isShowSize) {
      bus.$emit("hiddenSize");
        // bus.$emit("unDisplayMesh");
    }
  },
};
</script>
